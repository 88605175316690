import React from "react";
import Spacer from "./spacer";
import { GatsbyImage } from 'gatsby-plugin-image'

const Testimonials = ({ testimonials }) => {


    return testimonials.map(({ title, subtitle, role, image, paragraph, videoUrl }, index) => (
            <div className={"testimonals flex pl40 pr40 h-80vh m-h100  "} key={index}>

                <div className="max-1400 mla mra w-100 flex ">
                    <div className=" m-100 m-p0 flex flex-wrap  align-bottom mr20  m-m0">
                        <div className="w-100 flex-grow flex-grow-unset align-self--top">
                            <div className={"m0  banner--title align-self--top  w-100 "}>
                                <div className='m0 pt20  m-flex'>
                                    <div className=" p mr20 m-max-100">{title} </div>
                                    <div className="max-100 w-100 mla m-show  ">
                                        <div className="ratio-2-1 bg-grey banner--image mb40  ">
                                            <GatsbyImage className='bg-image' image={image?.gatsbyImageData} alt={title} />
                                        </div>
                                    </div>

                                </div>

                            </div>


                        </div>
                        <div className="text-bottom max-700 align-self--bottom m-align-self--unset ">

                            <Spacer className='m-hide ' />
                            <h3 className="h3 m0">  {paragraph}</h3>
                        </div>
                    </div>
                    <div className="max-180 w-100 mla m-hide mla    ">
                        <div className="ratio-2-1 bg-grey sticky--top mb40 banner--image m-t70      ">
                            <GatsbyImage className='bg-image' image={image?.gatsbyImageData} alt={title} />
                        </div>
                    </div>
                </div>
            </div>




     

    )
    )
}

export default Testimonials