import * as React from "react"
import ImageText from "./imageText"
import Spacer from "./spacer"
import ConditionallyRender from "./conditionallyRender"

const Insights = ({ insights }) => (
  insights.map(({ image, title, subtitle, url, name }, index) => (


    <ImageText autoplay={false} imageRatio='ratio-1-2  m-ratio-2-1' wrapperClassName={'p0 ' + ((index !== insights.length - 1) ? 'mb20' : '')} key={index} image={image} video={url} className='flex-reverse' >

      <div className={"inner p40 ma m-pt0 m-m0 "}>
        <Spacer className='m-show' />
        <ConditionallyRender when={title}>
          <p className="h4 m0">{title}</p>
          <Spacer className={' m-h-25  '} />
        </ConditionallyRender>

        <ConditionallyRender when={subtitle}>
          <p className={"h2 max-450 m-mt0 m-mb50"}>{subtitle}</p>
        </ConditionallyRender>
        <ConditionallyRender when={name}>
            <p className="h4 m0">{name}</p>
            <Spacer className={' m-h-25  '} />
        </ConditionallyRender>
      </div>

    </ImageText>


  )
  ))


export default Insights
