import React from 'react'
import ConditionallyRender from './conditionallyRender'
import ContentContainer from './contentContainer'
import Spacer from './spacer'

const LineDivider = ({ first =true, className,last}) => (
    <ContentContainer className='line-divider pt0 pb0'>
        <ConditionallyRender when={first}>
            <Spacer className={className} />
        </ConditionallyRender>
        <Spacer className={'bt1 ' + last} />
    </ContentContainer>
)
export default LineDivider